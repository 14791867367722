const BUILD_TARGET = {
  LOCALHOST: 'localhost',
  NNBOX_DEV: 'dev',
  NNBOX_STAGE: 'stage',
  NNBOX_QA02: 'qa02',
  NNBOX_QA03: 'qa03',
  NNBOX_LIVE_SN: 'sn', // 성남
  NNBOX_LIVE_YJ: 'yj', // 영종
  NNBOX_LIVE_GY: 'gy', // 덕양
  NNBOX_LIVE_GP: 'gp', // 김포
};
Object.freeze(BUILD_TARGET);

const getTargetInfo = (buildTarget) => {
  switch (buildTarget) {
    case BUILD_TARGET.LOCALHOST: {
      return {
        serverProtocol: 'http',
        serverIp: 'localhost',
        serverPort: '30001',
        wsProtocol: 'ws',
        wsHost: 'localhost',
        wsPath: '/ws/nnbox',
        wsPort: '30001',
        adminUrl: 'https://nnstage.nnbox.net:8000',
        settlementUrl: 'http://localhost:3001',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_DEV: {
      return {
        serverProtocol: 'http',
        serverIp: '127.0.0.1',
        serverPort: '30001',
        wsProtocol: 'ws',
        wsHost: '127.0.0.1',
        wsPath: '/ws/nnbox',
        wsPort: '30001',
        adminUrl: 'https://nnstage.nnbox.net:8000',
        // serverProtocol: "https",
        // serverIp: "dev01.nnbox.net",
        // wsProtocol: 'ws',
        // adminUrl:"https://nnstage.nnbox.net:8000",
        // settlementUrl: 'http://localhost:3001',
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_STAGE: {
      return {
        serverProtocol: 'https',
        serverIp: 'nnstage.nnbox.net',
        serverPort: '30001',
        wsProtocol: 'wss',
        wsHost: 'nnstage.nnbox.net',
        wsPath: '/ws/nnbox',
        wsPort: '30001',
        adminUrl: 'https://nnstage.nnbox.net:8000',
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_QA02: {
      return {
        serverProtocol: 'https',
        serverIp: 'qa02nadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'qa02nadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://nnstage.nnbox.net:8000',
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_QA03: {
      return {
        serverProtocol: 'https',
        serverIp: 'qa03nadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'qa03nadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://nnstage.nnbox.net:8000',
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_SN: {
      return {
        serverProtocol: 'https',
        serverIp: 'snnadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'snnadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://snadmin.nyamnyambox.com',
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'SN', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_YJ: {
      return {
        serverProtocol: 'https',
        serverIp: 'yjnadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'yjnadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://yjadmin.nyamnyambox.com',
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'YJ', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_GY: {
      return {
        serverProtocol: 'https',
        serverIp: 'gynadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'gynadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://gyadmin.nyamnyambox.com',
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'GY', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_GP: {
      return {
        serverProtocol: 'https',
        serverIp: 'nadia.nyamnyambox.com',
        serverPort: '443',
        wsProtocol: 'wss',
        wsHost: 'nadia.nyamnyambox.com',
        wsPath: '/ws/nnbox',
        wsPort: '443',
        adminUrl: 'https://admin.nyamnyambox.com',
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    default:
      return undefined;
  }
};

const buildTargetInfo = getTargetInfo(BUILD_TARGET.NNBOX_LIVE_YJ);

const Const = {
  appName: 'nnboxPartner',
  clientMinorVersion: 'v24061705',

  serverProtocol: buildTargetInfo.serverProtocol,
  serverIp: buildTargetInfo.serverIp,
  serverPort: buildTargetInfo.serverPort,
  wsProtocol: buildTargetInfo.wsProtocol,
  wsHost: buildTargetInfo.wsHost,
  wsPath: buildTargetInfo.wsPath,
  wsPort: buildTargetInfo.wsPort,
  adminUrl: buildTargetInfo.adminUrl,
  settlementUrl: buildTargetInfo.settlementUrl,
  region: buildTargetInfo.region,
  //drnana
  // serverProtocol: "https",
  // serverIp: "drnana.nnbox.net",

  //aws1
  // serverProtocol: "https",
  // serverIp: "gp.back.nyamnyambox.com",
  // wsProtocol: 'wss',

  // serverPort: "443",

  //naver map client key
  clientId: '',
  clientSecret: '',

  orderPollingInterval: 60000,
  completeOrderPollingInterval: 10000,
  riderPollingInterval: 20000,

  websocketPingIntervalMS: 60000,
};

export default Const;
